<template>
  <div class="modal text-gray-800 border-t-4 border-blue-500">
    <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Create New Sequence
      </h3>
    </div>

    <div class="modal-content px-8 py-4 bg-neutral-gray">
      <form
        v-if="scenes.length > 0"
        :class="{ 'error' : form.errors.get('form'), 'error' : formFormHasErrors }"
        @submit.prevent="submit"
      >
        <div class="mb-6">
          <label
            for="name"
            class="block text-sm font-medium leading-5 text-gray-700"
          >
            Sequence Name
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <input
              id="name"
              ref="nameinput"
              v-model="form.name"
              type="text"
              placeholder=""
              autofocus
              class="block w-full border border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5"
            >
          </div>
        </div>

        <div>
          <label
            for="sceneId"
            class="block text-sm font-medium leading-5 text-gray-700"
          >
            Parent Scene
          </label>

          <div class="mt-1 relative rounded-md shadow-sm">
            <select
              id="sceneId"
              v-model="sceneId"
              class="form-select block w-full text-sans text-gray-800 border-gray-100 rounded-sm"
            >
              <template v-for="scene in scenes">
                <option :value="scene.id">
                  {{ scene.name }}
                </option>
              </template>
            </select>
          </div>
        </div>

        <div class="mt-6 btn-group flex items-center justify-end">
          <button
            v-if="!form.busy"
            class="btn"
            type="button"
            @click="$emit('close')"
          >
            Cancel
          </button>
          <button
            v-if="form.busy"
            class="btn loading icon"
          >
            <fa-icon
              icon="spinner"
              spin
            /> Loading
          </button>
          <button
            v-else
            class="btn primary"
            type="button"
            @click.prevent="submit"
          >
            Create & Edit
          </button>
        </div>
      </form>
      <div v-else>
        <div class="my-2">
          <p class="text-gray-700 text-lg">
            Sorry, no Scenes were found for this new Sequence
          </p>
          <p class="text-gray-600 text-sm">
            First create or load a parent scenes, then create your sequence.
          </p>

          <div class="mt-6 btn-group flex items-center justify-end">
            <button
              class="btn"
              type="button"
              @click="$emit('close')"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    parent: {
      type: String,
      required: true
    },
    project: {
      type: Object,
      required: true
    },
    scenes: {
      type: Array,
      required: true
    },
    callback: Function
  },

  data () {
    return {
      form: new SparkForm({
        name: null
      }),
      sceneId: null,
      formFormHasErrors: false,
      hasAsset: false
    }
  },

  computed: {

    hasScenes () {
      return this.scenes.length > 0
    },

    readyToSubmit () {
      return this.form.name !== '' && this.form.sceneId !== '' && this.form.sceneId !== null
    }
  },

  watch: {
    scenes () {
      if (this.scenes.length > 0) {
        this.sceneId = this.scenes[0].id
      }
    }
  },

  mounted () {
    if (this.scenes.length > 0) {
      this.sceneId = this.scenes[0].id
    }
  },

  methods: {

    submit () {
      if (this.readyToSubmit && !this.form.busy) {
        this.formFormHasErrors = false
        this.form.startProcessing()

        this.$store.dispatch('assets/newSequenceItem', {
          name: this.form.name,
          sceneId: this.sceneId
        })
          .then(result => {
            this.form.finishProcessing()
            this.$emit('close')
            alert.success('Sequence created')

            // Auto navigate into the item immediately
            this.$router.push({ name: 'content_edit', params: { uuid: this.form.sceneId }, query: { sequence: result.id } })
          })
          .catch(err => {
            alert.error('Failed to create sequence')
            this.formFormHasErrors = true
            this.form.setErrors(err.data)
            this.form.finishProcessing()
          })
      }
    }

  }
}
</script>
